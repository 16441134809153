import i18n from "./i18n";
import axios from "axios";

const loadedLanguages = [];

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;

  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export async function loadLanguageAsync(lang) {
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang);
    return Promise.resolve();
  }
  // console.log(this.$store.state.settings);
  i18n.global.locale = lang;
  // return axios
  //   .get(`${process.env.VUE_APP_API_URL}api/translation/${lang}`)
  //   .then((response) => {
  //     console.log(response);
  //     // let msgs = response.data;
  //     // loadedLanguages.push(lang);
  //     // i18n.global.setLocaleMessage(lang, msgs.translations);
  //     i18n.global.locale = lang;
  //     // setI18nLanguage(lang);
  //   });
}
