import { createStore } from "vuex";

const store = createStore({
  state: {
    cssOverwrite: "",
    product: "",
    settings: {},
    brandSite: "",
    languages: [],
    receiptUploadActive: "",
    giftAvailable: false,
    logo: "",
  },
  getters: {
    getProduct(state) {
      return state.product;
    },
    getSettings(state) {
      return state.settings;
    },
    getApiUrl(state) {
      return state.settings.apiUrl;
    },
    getCssOverwrite(state) {
      return state.settings.cssOverwrite;
    },
    getLanguages(state) {
      return state.languages;
    },
    getGiftAvailable(state) {
      return state.giftAvailable;
    },
    getreceiptUploadActive(state) {
      return state.receiptUploadActive;
    },
    getLogo(state) {
      return state.logo;
    },
  },
  mutations: {
    setProduct(state, value) {
      state.product = value;
    },
    setSettings(state, value) {
      state.settings = value;
    },
    setBrandSite(state, value) {
      state.brandSite = value;
    },
    setCampaign(state, value) {
      state.campaign = value;
    },
    setCssOverwrite(state, value) {
      state.cssOverwrite = value;
    },
    setLanguages(state, value) {
      state.languages = value;
    },
    setGiftAvailable(state, value) {
      state.giftAvailable = value;
      console.log(state);
    },
    setreceiptUploadActive(state, value) {
      state.receiptUploadActive = value;
    },
    setLogo(state, value) {
      state.logo = value;
    },
  },
});

export default store;
