// i18n-setup.js
// import Vue from 'vue'
// import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n'

// Vue.use(VueI18n)

// export const i18n = new VueI18n({
//   locale: 'en',
//   fallbackLocale: 'en'
// })

export default createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
})