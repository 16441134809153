import { createRouter, createWebHistory } from "vue-router";
import App from "../App";
// import i18n from "../i18n";
import { loadLanguageAsync } from "../i18nHelper";

function load(component) {
  // '@' is aliased to src/components
  return () => import(`../views/${component}.vue`);
}

const routes = [
  {
    path: "/:locale",
    component: App,
    children: [
      {
        path: "verify/:token",
        name: "Verify",
        component: load("Verify"),
        props: true,
      },

      {
        path: ":campaign",
        name: "Home",
        component: load("Home"),
        props: true,
        children: [
          {
            path: ":product",
            name: "Home",
            component: load("Home"),
            props: true,
          },
        ],
      },

      // {
      //   path: ":product",
      //   name: "Home",
      //   component: load("Home"),
      //   props: true,
      //   children: [
      //     {
      //       path: ":campaign",
      //       name: "Home",
      //       component: load("Home"),
      //       props: true,
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   path: "/:pathMatch(.*)",
  //   redirect() {
  //     return process.env.VUE_APP_I18N_LOCALE;
  //   },
  // },
];

const router = createRouter({
  history: createWebHistory("/"),
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  const locale = to.params.locale; // 1
  // const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','); // 2
  // if (!supported_locales.includes(locale)) return next(process.env.VUE_APP_I18N_LOCALE); // 3
  // if (i18n.locale !== locale) { // 4
  //   i18n.locale = locale;
  //   i18n.global.locale = locale
  // }
  // return next() // 5
  loadLanguageAsync(locale).then(() => next());
});

export default router;
