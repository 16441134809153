// import { createApp } from "vue";
import { createApp } from "vue/dist/vue.esm-bundler";

import App from "./App.vue";
import store from "./utils/store";
import router from "./router";
import i18n from "./i18n";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSmoothScroll from "v-smooth-scroll";
import useVuelidate from "@vuelidate/core";
import SlideUpDown from "vue3-slide-up-down";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import "./assets/scss/_style.scss";

import { createHead } from "@vueuse/head";
const app = createApp(App);
const head = createHead();

app.use(store);
app.use(VueAxios, axios);
app.use(i18n);
app.use(router);
app.use(VueSmoothScroll);
app.use(useVuelidate);
app.use(head);

router.app = app;

app.component("slide-up-down", SlideUpDown);
app.component("Multiselect", Multiselect);

app.config.globalProperties.apiUrl = process.env.VUE_APP_API_URL;

app.config.compilerOptions.isCustomElement = (tag) => {
  let customElementArray = ["PriceBoxItem", "StepItem"];
  if (customElementArray.includes(tag)) return true;
};

app.mount("#app");
