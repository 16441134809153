<template>
  <router-view></router-view>
</template>

<script>
// import Store from './utils/store';
export default {
  name: "App",
  watch: {
    $route(to, from) {
      console.log("Route changed from " + from.path + " to " + to.path);
    },
  },
  mounted() {
    // fetch("/setting.json", {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((r) => r.json())
    //   .then((response) => {
    //     console.log(response);
    //     this.$store.commit("setSettings", response);
    //     // Store.settings = response;
    //     // console.log(Store.settings);
    //   })
    //   .catch((e) => console.log(e));
  },
};
</script>

<style lang="scss"></style>
